import css from 'styled-jsx/css'
import config from './config'

//TODO: Replace url with basePath
export const GlobalFontFaces = css.global`
    @font-face {
        font-family: 'Lato';
        src: local('Lato Black'), local('Lato-Black'), url('${config.basePath}/fonts/Lato-Black.woff2') format('woff2');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Lato';
        src: local('Lato BlackItalic'), local('Lato-BlackItalic'),
            url('${config.basePath}/fonts/Lato-BlackItalic.woff2') format('woff2');
        font-weight: 900;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Lato';
        src: local('Lato Bold'), local('Lato-Bold'), url('${config.basePath}/fonts/Lato-Bold.woff2') format('woff2');
        font-weight: bold;
        font-display: swap;
    }

    @font-face {
        font-family: 'Lato';
        src: local('Lato BoldItalic'), local('Lato-BoldItalic'),
            url('${config.basePath}/fonts/Lato-BoldItalic.woff2') format('woff2');
        font-weight: bold;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Lato';
        src: local('Lato Regular'), local('Lato-Regular'),
            url('${config.basePath}/fonts/Lato-Regular.woff2') format('woff2');
        font-weight: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Lato';
        src: local('Lato Italic'), local('Lato-Italic'),
            url('${config.basePath}/fonts/Lato-Italic.woff2') format('woff2');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Lato';
        src: local('Lato Light'), local('Lato-Light'), url('${config.basePath}/fonts/Lato-Light.woff2') format('woff2');
        font-weight: 300;
        font-display: swap;
    }

    @font-face {
        font-family: 'Lato';
        src: local('Lato LightItalic'), local('Lato-LightItalic'),
            url('${config.basePath}/fonts/Lato-LightItalic.woff2') format('woff2');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Lato';
        src: local('Lato Hairline'), local('Lato-Hairline'),
            url('${config.basePath}/fonts/Lato-Hairline.woff2') format('woff2');
        font-weight: 100;
        font-display: swap;
    }

    @font-face {
        font-family: 'Lato';
        src: local('Lato HairlineItalic'), local('Lato-HairlineItalic'),
            url('${config.basePath}/fonts/Lato-HairlineItalic.woff2') format('woff2');
        font-weight: 100;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Yummo';
        src: local('Yummo Bold'), local('Yummo-Bold'), url('${config.basePath}/fonts/Yummo-Bold.otf') format('opentype');
        font-weight: bold;
        font-display: swap;
    }

    @font-face {
        font-family: 'Yummo';
        src: local('Yummo SemiBold'), local('Yummo-SemiBold'),
            url('${config.basePath}/fonts/Yummo-SemiBold.otf') format('opentype');
        font-weight: 600;
        font-display: swap;
    }

    @font-face {
        font-family: 'Yummo';
        src: local('Yummo Regular'), local('Yummo-Regular'),
            url('${config.basePath}/fonts/Yummo-Regular.otf') format('opentype');
        font-weight: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Yummo';
        src: local('Yummo Light'), local('Yummo-Light'),
            url('${config.basePath}/fonts/Yummo-Light.otf') format('opentype');
        font-weight: 200;
        font-display: swap;
    }

    @font-face {
        font-family: 'Yummo';
        src: local('Yummo Thin'), local('Yummo-Thin'), url('${config.basePath}/fonts/Yummo-Thin.otf') format('opentype');
        font-weight: 100;
        font-display: swap;
    }
`
