import axios, { AxiosResponse } from 'axios'

import './setup-axios'
import {
    BodilessRequestConfig,
    DeleteRequestConfig,
    GetRequestConfig,
    PostRequestConfig,
    PutRequestConfig,
} from './types'
import { transformResponse } from '../../utils/transform-utils'
import config from '../../common/config'
import { isServer } from '../../utils/platform-utils'
import { isFunction } from '../../utils/type-utils'

class NetworkService {
    private readonly _baseUrl: string

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl
    }

    private static _handleNetworkResponse<T = any>(
        response: AxiosResponse<T>,
        successCallback: BodilessRequestConfig['successCallback'],
        giveCompleteResponse = false,
    ) {
        const _finalResponse = giveCompleteResponse ? response : response.data
        if (isFunction(successCallback)) {
            return successCallback(_finalResponse)
        }
        return _finalResponse
    }

    private static _handleNetworkError(error: any, errorCallback: BodilessRequestConfig['errorCallback']) {
        const err = (error.response && error.response.data) || error
        if (errorCallback) {
            return errorCallback(err)
        }
        throw err
    }

    public async get<T = any>({
        headers,
        url,
        params,
        restConfig = {},
        successCallback,
        errorCallback,
        completeResponse = false,
    }: GetRequestConfig): Promise<any> {
        const config = { headers, params, ...restConfig }
        const computedUrl = this._baseUrl + url
        try {
            const response = await axios.get<T>(computedUrl, config)
            return NetworkService._handleNetworkResponse(response, successCallback, completeResponse)
        } catch (error) {
            return NetworkService._handleNetworkError(error, errorCallback)
        }
    }

    public async post<T = any>({
        headers,
        url,
        params,
        restConfig = {},
        body,
        successCallback,
        errorCallback,
        completeResponse = false,
    }: PostRequestConfig): Promise<any> {
        const config = { headers, params, ...restConfig }
        const computedUrl = this._baseUrl + url
        try {
            const response = await axios.post<T>(computedUrl, body, config)
            return NetworkService._handleNetworkResponse(response, successCallback, completeResponse)
        } catch (error) {
            return NetworkService._handleNetworkError(error, errorCallback)
        }
    }

    public async put<T = any>({
        headers,
        url,
        params,
        restConfig = {},
        body,
        successCallback,
        errorCallback,
        completeResponse = false,
    }: PutRequestConfig): Promise<any> {
        const config = { headers, params, ...restConfig }
        const computedUrl = this._baseUrl + url
        try {
            const response = await axios.put<T>(computedUrl, body, config)
            return NetworkService._handleNetworkResponse(response, successCallback, completeResponse)
        } catch (error) {
            return NetworkService._handleNetworkError(error, errorCallback)
        }
    }

    public async delete<T = any>({
        headers,
        url,
        params,
        restConfig = {},
        successCallback,
        errorCallback,
        completeResponse = false,
    }: DeleteRequestConfig): Promise<any> {
        const config = { headers, params, ...restConfig }
        const computedUrl = this._baseUrl + url
        try {
            const response = await axios.delete<T>(computedUrl, config)
            return NetworkService._handleNetworkResponse(response, successCallback, completeResponse)
        } catch (error) {
            return NetworkService._handleNetworkError(error, errorCallback)
        }
    }

    /**
     * @deprecated
     *
     * Use
     * @link post
     *
     * @param headers
     * @param url
     * @param params
     * @param restConfig
     * @param body
     * @param successCallback
     * @param errorCallback
     * @param meta
     * @constructor
     */
    public async UNSAFE_post({
        headers,
        url,
        params,
        restConfig = {},
        body,
        successCallback,
        errorCallback,
        meta,
    }: PostRequestConfig): Promise<any> {
        const config = { headers, params, ...restConfig }
        const computedUrl = this._baseUrl + url
        try {
            const response = await axios.post(computedUrl, body, config)
            if (meta) {
                response.data.meta = meta
            }
            const transformedResponse: AxiosResponse | Promise<AxiosResponse> = await transformResponse(response)
            if (successCallback) {
                return successCallback(transformedResponse.data)
            }
            return transformedResponse.data
        } catch (error) {
            return NetworkService._handleNetworkError(error, errorCallback)
        }
    }

    /**
     *
     * @deprecated
     *
     * Use
     * @link put
     *
     * @param headers
     * @param url
     * @param params
     * @param restConfig
     * @param body
     * @param successCallback
     * @param errorCallback
     * @param meta
     * @constructor
     */
    public async UNSAFE_put({
        headers,
        url,
        params,
        restConfig = {},
        body,
        successCallback,
        errorCallback,
        meta,
    }: PutRequestConfig): Promise<any> {
        const config = { headers, params, ...restConfig }
        const computedUrl = this._baseUrl + url
        try {
            const response = await axios.put(computedUrl, body, config)
            if (meta) {
                response.data.meta = meta
            }
            const transformedResponse: AxiosResponse | Promise<AxiosResponse> = await transformResponse(response)
            if (successCallback) {
                return successCallback(transformedResponse.data)
            }
            return transformedResponse.data
        } catch (error) {
            return NetworkService._handleNetworkError(error, errorCallback)
        }
    }

    /**
     *
     * @deprecated
     *
     * Use
     * @link delete
     *
     * @param headers
     * @param url
     * @param params
     * @param restConfig
     * @param successCallback
     * @param errorCallback
     * @param meta
     */
    public async UNSAFE_delete({
        headers,
        url,
        params,
        restConfig = {},
        successCallback,
        errorCallback,
        meta,
    }: DeleteRequestConfig): Promise<any> {
        const config = { headers, params, ...restConfig }
        const computedUrl = this._baseUrl + url
        try {
            const response = await axios.delete(computedUrl, config)
            if (meta) {
                response.data.meta = meta
            }
            const transformedResponse: AxiosResponse | Promise<AxiosResponse> = await transformResponse(response)
            if (successCallback) {
                return successCallback(transformedResponse.data)
            }
            return transformedResponse.data
        } catch (error) {
            return NetworkService._handleNetworkError(error, errorCallback)
        }
    }
}

const baseUri = (isServer ? config.baseUrl : location.origin) + config.basePath

// TODO: Get this from config.
export default new NetworkService(baseUri)
