import { Device, defaultValues } from 'react-components'

export const isServer = typeof window === 'undefined'

export const getDefaultValuesForDevice = (device?: Device) => {
    if (!device || device.mobile) {
        return defaultValues.mobile
    }
    if (device.tablet) {
        return defaultValues.tablet
    }
    if (device.desktop) {
        return defaultValues.desktop
    }
    if (device.wideScreen) {
        return defaultValues.wideScreen
    }
    return defaultValues.mobile
}

export const isPlatformAndroidOrIOS =
    (typeof navigator !== 'undefined' && navigator?.userAgent?.includes('traum_app')) ?? false
