import { createContext } from 'react'

import { Routes } from '../routes/types'
import { defaultRoutes } from '../routes/config'

export interface RoutesContextValue {
    readonly routes: Routes
}

export const RoutesContext = createContext<RoutesContextValue>({
    routes: defaultRoutes,
})

export const RoutesContextProvider = RoutesContext.Provider
export const RoutesContextConsumer = RoutesContext.Consumer
