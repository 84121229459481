import UserDetailsTransport from '../../transports/user-details-transport'
import { Role } from '../../components/search-result-listing/header-section/types'

class UserDetailsModel {
    public readonly id: string
    /**
     * List of strings such as "openid", "profile", "email", "address", "phone", "offline", "tfw-user-profile",
     * "tfw-sso".
     */
    public readonly scp: string[]
    public readonly name: string
    public readonly email: string
    public readonly phone: string
    public readonly locale: string
    public readonly country: string
    public readonly locality: string
    public readonly formatted: string
    public readonly firstName: string
    public readonly familyName: string
    public readonly postalCode: string
    public readonly tfwPersonId: number
    public readonly streetAddress: string
    public readonly tfwSalutation: string
    public readonly tfwLanguageId: number
    public readonly tfwMobilePhone: string
    public readonly tfwPrimaryRole: Role
    public readonly tfwSalutationId: number
    public readonly tfwSsoDeviceId: string
    public readonly preferredUsername: string
    public readonly tfwSecondFactorMethod: string
    public readonly tfwSsoDeviceIdExpires: number
    public readonly favoriteId?: string
    public readonly favoriteCount?: number
    public readonly favoriteListings?: string[]
    public readonly favoriteSeed?: string
    public readonly inquiryCount?: number

    constructor({
        id,
        scp,
        name,
        email,
        phone,
        locale,
        country,
        locality,
        formatted,
        firstName,
        familyName,
        postalCode,
        tfwPersonId,
        streetAddress,
        tfwSalutation,
        tfwLanguageId,
        tfwMobilePhone,
        tfwPrimaryRole,
        tfwSalutationId,
        tfwSsoDeviceId,
        preferredUsername,
        tfwSecondFactorMethod,
        tfwSsoDeviceIdExpires,
        favoriteId,
        favoriteCount,
        favoriteListings,
        favoriteSeed,
        inquiryCount,
    }: {
        id: string
        scp: string[]
        name: string
        email: string
        phone: string
        locale: string
        country: string
        locality: string
        formatted: string
        firstName: string
        familyName: string
        postalCode: string
        tfwPersonId: number
        streetAddress: string
        tfwSalutation: string
        tfwLanguageId: number
        tfwMobilePhone: string
        tfwPrimaryRole: Role
        tfwSalutationId: number
        tfwSsoDeviceId: string
        preferredUsername: string
        tfwSecondFactorMethod: string
        tfwSsoDeviceIdExpires: number
        favoriteId?: string
        favoriteCount?: number
        favoriteListings?: string[]
        favoriteSeed?: string
        inquiryCount?: number
    }) {
        this.id = id
        this.scp = scp
        this.name = name
        this.email = email
        this.phone = phone
        this.locale = locale
        this.country = country
        this.locality = locality
        this.formatted = formatted
        this.firstName = firstName
        this.familyName = familyName
        this.postalCode = postalCode
        this.tfwPersonId = tfwPersonId
        this.streetAddress = streetAddress
        this.tfwSalutation = tfwSalutation
        this.tfwLanguageId = tfwLanguageId
        this.tfwMobilePhone = tfwMobilePhone
        this.tfwPrimaryRole = tfwPrimaryRole
        this.tfwSalutationId = tfwSalutationId
        this.tfwSsoDeviceId = tfwSsoDeviceId
        this.preferredUsername = preferredUsername
        this.tfwSecondFactorMethod = tfwSecondFactorMethod
        this.tfwSsoDeviceIdExpires = tfwSsoDeviceIdExpires
        this.favoriteId = favoriteId
        this.favoriteCount = favoriteCount
        this.favoriteListings = favoriteListings
        this.favoriteSeed = favoriteSeed
        this.inquiryCount = inquiryCount
    }

    public static buildFromResponse(data: UserDetailsTransport): UserDetailsModel {
        return new UserDetailsModel(data)
    }
}

export default UserDetailsModel
