import { useCallback, useEffect, useRef, useState } from 'react'
import { CallbackFunction } from '../common/types'
import { isFunction } from '../utils/type-utils'

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
    handler: (event: MouseEvent | TouchEvent) => void,
) => {
    const ref = useRef<T>(null)

    const listener = useCallback(
        (event: MouseEvent | TouchEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
                handler(event)
            }
        },
        [handler],
    )

    useEffect(() => {
        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [listener])

    return { ref }
}

export const useHover = () => {
    const [isBeingHovered, setIsBeingHovered] = useState(false)

    const _handleMouseEnter = useCallback(() => setIsBeingHovered(true), [])
    const _handleMouseLeave = useCallback(() => setIsBeingHovered(false), [])
    const _handleClick = useCallback(() => setIsBeingHovered(isBeingHovered => !isBeingHovered), [])

    return {
        isBeingHovered,
        setIsBeingHovered,
        onMouseEnter: _handleMouseEnter,
        onMouseLeave: _handleMouseLeave,
        onClick: _handleClick,
    }
}

const _loadScript = (url: string, { defer = false }: { defer?: boolean } = {}, onLoad?: CallbackFunction) => {
    let script: HTMLScriptElement | null = document.querySelector(`script[src="${url}"]`)

    if (!script) {
        script = document.createElement('script')
        script.src = url
        script.type = 'text/javascript'
        script[defer ? 'defer' : 'async'] = true
        document.head.append(script)

        script.addEventListener('error', () => {
            if (script) {
                script.dataset.failed = 'true'
            }
        })
        script.addEventListener('load', () => {
            if (script) {
                script.dataset.loaded = 'true'
            }
        })
    }

    // Already loaded, so we can return early.
    if (script?.dataset.loaded === 'true') {
        onLoad?.()
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {}
    }

    if (!isFunction(onLoad)) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {}
    }

    // Add load event listener.
    script.addEventListener('load', onLoad)

    return () => {
        if (script) {
            script.removeEventListener('load', onLoad)
        }
    }
}

/**
 *  Hook to load an external script. Returns true once the script has finished loading.
 */
export const useScript = (url: string, { defer = false }: { defer?: boolean } = {}, onLoad?: CallbackFunction) => {
    const [ready, setReady] = useState(false)

    const callback = useCallback(() => {
        if (isFunction(onLoad)) {
            onLoad()
        }
        setReady(true)
    }, [onLoad])

    useEffect(() => _loadScript(url, { defer }, callback), [callback, url, defer])

    return [ready]
}
