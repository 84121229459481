import { useMemo } from 'react'
import { applyMiddleware, CombinedState, compose, createStore, Store } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createMigrate, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { MigrationManifest } from 'redux-persist/es/types'
import { logger } from 'redux-logger'

import rootReducer from './reducer'
import config from '../common/config'
import RootState from './types'
import { isServer } from '../utils/platform-utils'
import migrations from './migrations'
import { searchbarTransform } from './transformer'

const middleWares = [thunkMiddleware]
if (config.environment !== 'production') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    middleWares.push(logger)
}
const middleWareEnhancer = applyMiddleware(...middleWares)

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
    typeof (window as any) === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose
/* eslint-enable */

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['searchbar'],
    migrate: createMigrate(migrations as unknown as MigrationManifest, { debug: config.environment !== 'production' }),
    transforms: [searchbarTransform],
}

const initStore = (preloadedState?: RootState) =>
    createStore(persistReducer(persistConfig, rootReducer), preloadedState, composeEnhancers(middleWareEnhancer))

let store: Store<CombinedState<RootState>> | undefined

export const initializeStore = (preloadedState?: RootState) => {
    let _store = store ?? initStore(preloadedState)

    // After navigating to a page with an initial Redux state, merge that state with the current state in the store,
    // and create a new store.
    if (preloadedState && store) {
        _store = initStore({
            ...store.getState(),
            ...preloadedState,
        })
        // Reset the current store.
        store = undefined
    }

    // For SSG and SSR always create a new store.
    if (isServer) {
        return _store
    }

    // Create the store once in the client.
    if (!store) {
        store = _store
    }

    return _store
}

export const useStore = (initialState?: RootState) => {
    const store = useMemo(() => initializeStore(initialState), [initialState])
    const persistor = useMemo(() => persistStore(store), [store])
    return { store, persistor }
}

export const getStore = () => store
