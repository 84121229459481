import React, { Fragment, useEffect, useState } from 'react'
import { AppProps, NextWebVitalsMetric } from 'next/app'
import { ThemeProvider } from 'styled-components'
import { queries, ScreenProvider, theme } from 'react-components'
import { polyfill } from 'smoothscroll-polyfill'
import Head from 'next/head'

import 'normalize.css'

import { GlobalFontFaces } from '../common/fonts'
import { getDefaultValuesForDevice, isServer } from '../utils/platform-utils'
import { PageProps } from '../common/types'
import ReduxProvider from '../redux/provider'
import UserSettingsConfigProvider from '../provider/user-settings-config'
import GlobalStyles from '../components/common/global-styles'
import { useDateFormatter } from '../hooks/date'
import firebaseConfig, { performanceStandaloneUrl } from '../common/firebase'
import { useScript } from '../hooks/dom'

const initializeFirebase = () => {
    if (window.firebase && firebaseConfig.apiKey) {
        // Initialize firebase with Firebase App config.
        window.firebase.initializeApp(firebaseConfig).performance()
    }
}

interface OwnProps {
    // Workaround for https://github.com/zeit/next.js/issues/8592.
    err?: any
}

interface Props extends OwnProps, AppProps {
    pageProps: PageProps
}

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
    if (
        typeof window !== 'undefined' &&
        window.dataLayer?.push &&
        !!document.getElementById('region-landing-page-main') &&
        label === 'web-vital' &&
        (name === 'LCP' || name === 'FID' || name === 'CLS')
    ) {
        window.dataLayer.push({
            event: 'web-vitals',
            event_category: 'Web Vitals',
            event_action: name,
            // Google Analytics metrics must be integers, so the value is rounded.
            // For CLS the value is first multiplied by 1000 for greater precision
            // (note: increase the multiplier for greater precision if needed).
            event_value: Math.round(name === 'CLS' ? value * 1000 : value),
            // The 'id' value will be unique to the current page load. When sending
            // multiple values from the same page (e.g. for CLS), Google Analytics can
            // compute a total by grouping on this ID (note: requires `eventLabel` to
            // be a dimension in your report).
            event_label: id,
        })
    }
}

const App = ({ Component, pageProps, err }: Props) => {
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        polyfill()
        setIsClient(!isServer)
    }, [])

    const { device, initialReduxState, requestUserConfig, messages, routes, ...restPageProps } = pageProps

    // Call at the top-level so that formatter method is defined.
    useDateFormatter()

    useScript(performanceStandaloneUrl, { defer: false }, initializeFirebase)

    return (
        <Fragment>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <ScreenProvider queries={queries} values={isClient ? undefined : getDefaultValuesForDevice(device)}>
                <ThemeProvider theme={theme}>
                    <UserSettingsConfigProvider
                        requestUserConfig={requestUserConfig}
                        messages={messages}
                        routes={routes}
                    >
                        <style jsx global>
                            {GlobalFontFaces}
                        </style>
                        <GlobalStyles />
                        <ReduxProvider initialState={initialReduxState}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <Component {...restPageProps} err={err} />
                        </ReduxProvider>
                    </UserSettingsConfigProvider>
                </ThemeProvider>
            </ScreenProvider>
        </Fragment>
    )
}

export default App
