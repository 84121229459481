import { createReducer } from 'redux-act'

import State from './types'
import BaseTransport from '../../transports/common/base-transport'
import { startUserDetailsTransport, saveUserDetailsTransport, errorUserDetailsTransport } from './actions'
import initialState from './state'
import UserDetailsTransport from '../../transports/user-details-transport'

const _startUserDetailsTransport = (state: State): State => ({
    ...state,
    userDetails: {
        ...state.userDetails,
        isLoading: true,
        error: null,
    },
})

const _saveUserDetailsTransport = (state: State, payload: BaseTransport<UserDetailsTransport>): State => ({
    ...state,
    userDetails: {
        ...state.userDetails,
        isLoading: false,
        transport: payload,
    },
})

const _errorUserDetailsTransport = (state: State, payload: Error): State => ({
    ...state,
    userDetails: {
        ...state.userDetails,
        isLoading: false,
        error: payload,
    },
})

const reducer = createReducer<State>(on => {
    on(startUserDetailsTransport, _startUserDetailsTransport)
    on(saveUserDetailsTransport, _saveUserDetailsTransport)
    on(errorUserDetailsTransport, _errorUserDetailsTransport)
}, initialState)

export default reducer
