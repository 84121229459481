import { createSelector } from 'reselect'

import RootState from '../types'
import State, { SearchbarHistoryItem } from './types'

const _getSearchbarState = (state: RootState) => state.searchbar

export const getSearchbarHistoryItemsState = createSelector<RootState, State, SearchbarHistoryItem[]>(
    _getSearchbarState,
    searchbarHistory => searchbarHistory.historyItems,
)
