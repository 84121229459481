import { createTransform } from 'redux-persist'
import { parseISO } from 'date-fns'

import { SearchbarState } from './searchbar'

export const searchbarTransform = createTransform<SearchbarState, SearchbarState>(
    // Serializing transform. Do nothing as the default serialization process is OK.
    inboundSubState => inboundSubState,
    // Deserializing transform. Read dates as `Date` objects.
    outboundSubState => {
        return {
            ...outboundSubState,
            historyItems: outboundSubState.historyItems.map(historyItem => {
                const { arrival, departure } = historyItem
                return {
                    ...historyItem,
                    arrival: arrival ? parseISO(arrival as unknown as string) : null,
                    departure: departure ? parseISO(departure as unknown as string) : null,
                }
            }),
        }
    },
    { whitelist: ['searchbar'] },
)
