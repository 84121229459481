import { createAction } from 'redux-act'

import { Thunk } from '../types'
import BaseTransport from '../../transports/common/base-transport'
import {
    ERROR_GET_USER_DETAILS_TRANSPORT,
    SAVE_GET_USER_DETAILS_TRANSPORT,
    START_GET_USER_DETAILS_TRANSPORT,
} from './action-types'
import UserDetailsTransport, { UserDetailsParamsTransport } from '../../transports/user-details-transport'
import { userClient } from '../../clients'
import { CallbackFunction } from '../../common/types'
import { isFunction } from '../../utils/type-utils'
import { getUserDetailsTransportResponseState } from './selectors'

export const startUserDetailsTransport = createAction(START_GET_USER_DETAILS_TRANSPORT)
export const saveUserDetailsTransport = createAction<BaseTransport<UserDetailsTransport>>(
    SAVE_GET_USER_DETAILS_TRANSPORT,
)
export const errorUserDetailsTransport = createAction<Error>(ERROR_GET_USER_DETAILS_TRANSPORT)
export const getUserDetails =
    ({
        successCallback,
        errorCallback,
        params,
    }: {
        successCallback?: CallbackFunction
        errorCallback?: CallbackFunction
        params?: UserDetailsParamsTransport
    } = {}): Thunk =>
    async (dispatch, getState) => {
        const { isLoading } = getUserDetailsTransportResponseState(getState())

        if (isLoading) {
            return
        }

        dispatch(startUserDetailsTransport())
        try {
            const response = await userClient.getUserDetails({ params })
            dispatch(saveUserDetailsTransport(response))
            if (isFunction(successCallback)) {
                return successCallback(response)
            }
        } catch (error) {
            dispatch(errorUserDetailsTransport(error))
            if (isFunction(errorCallback)) {
                return errorCallback(error)
            }
        }
    }
