import { createReducer } from 'redux-act'

import State, { SearchbarHistoryItem } from './types'
import { saveHistoryItems } from './actions'
import initialState from './state'

const _saveHistoryItems = (state: State, payload: SearchbarHistoryItem[]): State => {
    return {
        ...state,
        historyItems: payload,
    }
}

const reducer = createReducer<State>(on => {
    on(saveHistoryItems, _saveHistoryItems)
}, initialState)

export default reducer
