import { NetworkService } from '../services'
import { BaseTransport } from '../transports'
import UserDetailsTransport, { UserDetailsParamsTransport } from '../transports/user-details-transport'

class UserClient {
    public getUserDetails({ params }: { params?: UserDetailsParamsTransport } = {}): Promise<
        BaseTransport<UserDetailsTransport>
    > {
        return NetworkService.get({
            url: '/api/users/v1/user-details',
            params,
        })
    }
}

export default new UserClient()
