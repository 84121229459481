import { isServer } from './platform-utils'

export const decodeFromBase64 = (string: string): string => {
    if (isServer) {
        return Buffer.from(string, 'base64').toString()
    }
    return atob(string)
}

export const encodeToBase64 = (string: string): string => {
    if (isServer) {
        return Buffer.from(string).toString('base64')
    }
    return btoa(string)
}
