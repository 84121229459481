import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { Locale, Messages } from './types'
import { LocaleContextProvider } from '../context/locale'
import { getMessages, isLocale } from './utils'
import { CookieManagerService } from '../services'

interface LocaleProviderProps {
    initialLocale: Locale
    initialMessages: Messages
}

const LocaleProvider: FC<LocaleProviderProps> = ({ initialLocale, initialMessages, children }) => {
    const [locale, setLocale] = useState(initialLocale)
    const [messages, setMessages] = useState(initialMessages)
    const { query } = useRouter()

    // Persist locale whenever it changes.
    useEffect(() => {
        getMessages(locale)
            .then(messages => {
                setLocale(locale)
                setMessages(messages)
                if (locale !== CookieManagerService.getCookie(CookieManagerService.PREFERRED_LOCALE)) {
                    CookieManagerService.setCookie(CookieManagerService.PREFERRED_LOCALE, locale, {
                        expires: 365,
                        sameSite: 'strict',
                    })
                }
            })
            .catch(error => {
                console.error({ error })
            })
    }, [locale])

    const changeLocale = useCallback((locale: string) => {
        if (isLocale(locale)) {
            setLocale(locale)
        }
    }, [])

    useEffect(() => {
        if (typeof query.lang === 'string' && locale !== query.lang) {
            changeLocale(locale)
        }
        // Disable the linter here because we only want this hook to run when `query` has changed, not when `locale`
        // itself changes.
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [query.lang, changeLocale])

    const contextValue = useMemo(() => ({ locale, changeLocale, messages }), [locale, changeLocale, messages])

    return <LocaleContextProvider value={contextValue}>{children}</LocaleContextProvider>
}

export default LocaleProvider
