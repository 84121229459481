import { useMemo } from 'react'
/* eslint-disable import/no-duplicates */
import { format as formatDate, Locale as DateLocale } from 'date-fns'
import {
    de as deDateLocale,
    deAT as deATDateLocale,
    enGB as enGBDateLocale,
    enUS as enUSDateLocale,
    nl as nlDateLocale,
    fr as frDateLocale,
    it as itDateLocale,
    es as esDateLocale,
    hr as hrDateLocale,
} from 'date-fns/locale'
/* eslint-enable import/no-duplicates */

import { useLocale } from './locale'
import { Locale } from '../i18n/types'
import { FormatDateFunction } from '../common/types'

// TODO: If possible, load these in chunks. The fact that `format` function is required right away while rendering on
//  the server makes it hard to do so.
const _getDateLocale = (locale: Locale) => {
    switch (locale) {
        case 'en-GB':
            return enGBDateLocale
        case 'en-US':
            return enUSDateLocale
        case 'de-AT':
            return deATDateLocale
        case 'nl-NL':
            return nlDateLocale
        case 'fr-FR':
            return frDateLocale
        case 'it-IT':
            return itDateLocale
        case 'es-ES':
            return esDateLocale
        case 'hr-HR':
            return hrDateLocale
        default:
            return deDateLocale
    }
}

let _locale: Locale
let dateLocale: DateLocale
export let format: FormatDateFunction

/**
 * MUST READ: This function needs to be called at the top level before any actual rendering has taken place.
 * It is required so that the above declared {@link format} method is actually defined for use in any of the other
 * places.
 */
export const useDateFormatter = () => {
    const { locale } = useLocale()

    return useMemo(() => {
        if (_locale !== locale) {
            _locale = locale
            dateLocale = _getDateLocale(locale)
            format = (date: Date | number, formatStyle: string) => {
                return formatDate(date, formatStyle, { locale: dateLocale })
            }
        }
        return { dateLocale, format }
    }, [locale])
}
