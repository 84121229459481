import { combineReducers } from 'redux'

import { searchbarReducer as searchbar } from './searchbar'
import { reducer as user } from './user'
import { reducer as wishlist } from './wishlist'
import RootState from './types'

const rootReducer = combineReducers<RootState>({ searchbar, user, wishlist })

export default rootReducer
