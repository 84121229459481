import deTranslations from './translations/de-DE.json'
import { Messages } from './types'

export const defaultMessages = deTranslations as Messages

export const defaultLocale = 'de-DE'

export const locales = [
    'de-AT',
    'de-CH',
    'de-DE',
    'en-GB',
    'en-US',
    'es-ES',
    'fr-FR',
    'hr-HR',
    'it-IT',
    'nl-NL',
] as const
