import React, { FC, useMemo, useState } from 'react'

import { RoutesContextProvider } from '../context/routes'
import { Routes } from './types'

interface Props {
    initialRoutes: Routes
}

const RoutesProvider: FC<Props> = ({ initialRoutes, children }) => {
    const [routes] = useState(initialRoutes)

    const contextValue = useMemo(() => ({ routes }), [routes])

    return <RoutesContextProvider value={contextValue}>{children}</RoutesContextProvider>
}

export default RoutesProvider
