import { createReducer } from 'redux-act'

import State from './types'
import { decrementCountTransport, incrementCountTransport, setCountTransport } from './actions'
import initialState from './state'

const _setCountTransport = (state: State, payload: number | undefined): State => ({
    ...state,
    count: payload,
})

const _incrementCountTransport = (state: State): State => ({
    ...state,
    count: state.count ? state.count + 1 : 1,
})

const _decrementCountTransport = (state: State): State => ({
    ...state,
    count: state.count && state.count > 1 ? state.count - 1 : undefined,
})

const reducer = createReducer<State>(on => {
    on(setCountTransport, _setCountTransport)
    on(incrementCountTransport, _incrementCountTransport)
    on(decrementCountTransport, _decrementCountTransport)
}, initialState)

export default reducer
