import Cookies, { CookieAttributes } from 'js-cookie'
import { Request } from 'express'

import { isServer } from '../utils/platform-utils'

class CookieManagerService {
    public static readonly DEVICE_SESSION_ID = 'heracles_oauth_device_verifier'
    public static readonly FAVORITE_LISTINGS_IDS = 'favorite_listing_ids'
    public static readonly SEARCH_PARAMS = 'searchParams'
    public static readonly PREFERRED_LOCALE = 'preferred_locale'
    public static readonly XSRF_TOKEN = 'XSRF-TOKEN'

    public static setCookie(key: string, value: string | object, options?: CookieAttributes): void {
        if (!isServer) {
            Cookies.set(key, value, options)
        }
    }

    public static appendValueInCookieList(key: string, value: string, options?: CookieAttributes): void {
        if (!isServer) {
            const stringValue = Cookies.get(key)
            if (!!stringValue) {
                const listValues: string[] = stringValue.split(',')
                if (!listValues.includes(value)) {
                    listValues.push(value)
                    Cookies.set(key, listValues.join(','), options)
                }
            } else {
                Cookies.set(key, value, options)
            }
        }
    }

    public static getCookie(key: string, req?: Request): string | undefined {
        if (isServer) {
            return req?.cookies[CookieManagerService.DEVICE_SESSION_ID]
        }
        return Cookies.get(key)
    }

    public static isValueAppendedInCookieList(key: string, value: string): boolean {
        if (!isServer) {
            const stringValue = Cookies.get(key)
            if (!!stringValue) {
                return stringValue.split(',').includes(value)
            }
            return false
        }
        return false
    }

    public static removeValueFromCookieList(key: string, value: string, options?: CookieAttributes): void {
        if (!isServer) {
            const stringValue = Cookies.get(key)
            if (!!stringValue) {
                const listValues: string[] = stringValue.split(',')
                if (listValues.includes(value)) {
                    const index = listValues.indexOf(value)
                    listValues.splice(index, 1)

                    if (listValues.length === 0) {
                        Cookies.remove(key, options)
                    } else {
                        Cookies.set(key, listValues.join(','), options)
                    }
                }
            }
        }
    }

    public static deleteCookie(key: string, options?: CookieAttributes): void {
        if (!isServer) {
            Cookies.remove(key, options)
        }
    }
}

export default CookieManagerService
