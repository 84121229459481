import initialSearchBarState from './searchbar/state'
import { MigrationsMap } from './types'

const migrations: MigrationsMap = {
    '0': state => {
        // Reset `searchbar` state.
        return {
            ...state,
            searchbar: initialSearchBarState,
        }
    },
}

export default migrations
