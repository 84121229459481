import config from './config'

const {
    firebaseApiKey,
    firebaseAppId,
    firebaseMessagingSenderId,
    firebaseAuthDomain,
    firebaseProjectId,
    firebaseStorageBucket,
} = config

// Firebase performance only SDK url
export const performanceStandaloneUrl = 'https://www.gstatic.com/firebasejs/7.15.5/firebase-performance-standalone.js'

export interface FirebaseConfig {
    apiKey?: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId?: string
    appId?: string
}

// App's Firebase configuration
const firebaseConfig: FirebaseConfig = {
    apiKey: firebaseApiKey,
    authDomain: firebaseAuthDomain,
    projectId: firebaseProjectId,
    storageBucket: firebaseStorageBucket,
    messagingSenderId: firebaseMessagingSenderId,
    appId: firebaseAppId,
}

export default firebaseConfig
