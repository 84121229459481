import { createAction } from 'redux-act'
import { isEqual } from 'date-fns'

import { Thunk } from '../types'
import { SAVE_HISTORY_ITEMS } from './action-types'
import { BaseSearchbarHistoryItem, SearchbarHistoryItem } from './types'
import { getSearchbarHistoryItemsState } from './selectors'

export const saveHistoryItems = createAction<SearchbarHistoryItem[]>(SAVE_HISTORY_ITEMS)

export const saveHistoryItem =
    ({ search, arrival, departure, adults, children }: BaseSearchbarHistoryItem): Thunk =>
    (dispatch, getState) => {
        const historyItems = [...getSearchbarHistoryItemsState(getState())]

        const index = historyItems.findIndex(
            historyItem =>
                historyItem.search === search &&
                // Check for equality will be successful only when object references are same, or when both are `null`.
                (historyItem.arrival === arrival ||
                    (historyItem.arrival && arrival && isEqual(historyItem.arrival, arrival))) &&
                (historyItem.departure === departure ||
                    (historyItem.departure && departure && isEqual(historyItem.departure, departure))) &&
                historyItem.adults === adults &&
                historyItem.children === children,
        )

        const item = {
            search,
            arrival,
            departure,
            adults,
            children,
            _time: Date.now(),
            _count: index !== -1 ? historyItems[index]._count + 1 : 1,
        }

        if (index !== -1) {
            historyItems.splice(index, 1)
        }
        historyItems.unshift(item)
        if (historyItems.length > 5) {
            historyItems.pop()
        }

        dispatch(saveHistoryItems(historyItems))
    }
