import { createContext } from 'react'

import { RequestUserConfig } from '../common/types'
import { getDomain, getLanguage } from '../utils/request-utils'

export const RequestUserConfigContext = createContext<RequestUserConfig>({
    domain: getDomain(),
    language: getLanguage(),
})

export const RequestUserConfigContextProvider = RequestUserConfigContext.Provider
export const RequestUserConfigContextConsumer = RequestUserConfigContext.Consumer
