import { AxiosResponse } from 'axios'

import { transformTransportData } from './model-utils'

export const transformResponse = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
    if (response.data) {
        const resourceType = response.data.meta && response.data.meta.resource ? response.data.meta.resource : null
        if (response.data.data) {
            response.data.data = transformTransportData(resourceType, response.data.data)
            return response
        } else {
            const transformedData = transformTransportData(resourceType, response.data)
            return {
                ...response,
                data: {
                    status: response.status,
                    data: transformedData,
                    meta: response.data.meta,
                },
            }
        }
    }
    return response
}
