import { Request } from 'express'

import config from '../common/config'
import { Language } from '../common/types'
import { isServer } from './platform-utils'
import { encodeToBase64 } from './encoding-utils'

const _getHostnameLanguageMap = (environment: string) => {
    switch (environment) {
        case 'production':
            return new Map<string, Language>([
                ['traum-ferienwohnungen.de', 'de_DE'],
                ['traum-ferienwohnungen.ch', 'de_CH'],
                ['traum-ferienwohnungen.at', 'de_AT'],
                ['vacation-apartments.com', 'en_US'],
                ['holiday-apartments.co.uk', 'en_GB'],
                ['traumhomes.nl', 'nl_NL'],
                ['traumhomes.fr', 'fr_FR'],
                ['traumhomes.it', 'it_IT'],
                ['traumhomes.es', 'es_ES'],
                ['traumhomes.hr', 'hr_HR'],
            ])
        case 'staging':
            return new Map<string, Language>([[config.baseUrl, 'de_DE']])
        default:
            return new Map<string, Language>([['localhost', 'de_DE']])
    }
}

const _hostnameLanguageMap = _getHostnameLanguageMap(config.environment)

const _getLanguageHostnameMap = () => {
    const languageHostnameMap = new Map<Language, string>()
    _hostnameLanguageMap.forEach((language, hostname) => {
        languageHostnameMap.set(language, hostname)
    })
    return languageHostnameMap
}

const _languageHostnameMap = _getLanguageHostnameMap()

const _getDomainPerEnvironment = (environment: string) => {
    switch (environment) {
        case 'production':
            return (hostname?: string | null): string => {
                const currentHostname = hostname ?? (!isServer ? location.hostname : 'traum-ferienwohnungen.de')
                const match = currentHostname.match(
                    // eslint-disable-next-line max-len
                    /(www\.)?(traum-ferienwohnungen|vacation-apartments|holiday-apartments|traumhomes)(\.de|\.ch|\.at|\.co\.uk|\.com|\.nl|\.fr|\.it|\.es|\.hr)/,
                )

                return match ? match[match.length - 2] + match[match.length - 1] : 'traum-ferienwohnungen.de'
            }
        case 'staging':
            return (hostname?: string | null): string => {
                const currentHostname = hostname ?? (!isServer ? location.hostname : config.baseUrl)

                const match = currentHostname.match(/(guest-website-frontend[\w-]?.*-proxy)(\.svc\.staging\.tfw\.io)/)
                return match ? match[match.length - 2] + match[match.length - 1] : config.baseUrl
            }
        default:
            return (): string => {
                return 'localhost'
            }
    }
}

export const getDomain = _getDomainPerEnvironment(config.environment)

export const getLanguage = (hostname?: string | null): Language => {
    const domain = getDomain(hostname)
    return _hostnameLanguageMap.get(domain) ?? 'de_DE'
}

export const getDomainFromLanguage = (language: Language): string =>
    _languageHostnameMap.get(language) ?? _languageHostnameMap.get('de_DE') ?? 'traum-ferienwohnungen.de'

export const getFilterFromRequest = (req: Request) => encodeToBase64(req.originalUrl)
export const getFilterFromHref = (href: string) => encodeToBase64(href)
