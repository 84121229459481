import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import RootState from './types'
import { useStore } from './store'

interface Props {
    initialState?: RootState
}

const ReduxProvider: FC<Props> = ({ initialState, children }) => {
    const { store, persistor } = useStore(initialState)

    return (
        <Provider store={store}>
            {/* Render the same `children` inside `loading` in case of SSR so as to still generate the HTML. */}
            <PersistGate persistor={persistor} loading={children}>
                {children}
            </PersistGate>
        </Provider>
    )
}

export default ReduxProvider
