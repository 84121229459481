import { defaultLocale, defaultMessages, locales } from './config'
import { Locale, Messages } from './types'
import { Language } from '../common/types'

export const isLocale = (tested?: string): tested is Locale => !!tested && locales.includes(tested as Locale)

export const getMessagesFromLanguage = (language: Language) => getMessages(getLocaleFromLanguage(language))

export const getMessages = (locale: string): Promise<Messages> =>
    isLocale(locale)
        ? import(`./translations/${locale}.json`).then(({ default: messages }) => messages)
        : new Promise(resolve => resolve(defaultMessages))

export const getLocaleFromLanguage = (language: Language) => language.split('_').join('-') as Locale

export const getLanguageFromLocale = (locale?: string) =>
    (isLocale(locale) ? locale : defaultLocale).split('-').join('_') as Language
