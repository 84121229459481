import { createSelector } from 'reselect'

import RootState, { TransportResponseState } from '../types'
import State from './types'
import BaseTransport from '../../transports/common/base-transport'
import UserDetailsTransport from '../../transports/user-details-transport'
import UserDetailsModel from '../../models/user/user-details-model'
import { getModel } from '../../utils/model-utils'

const _getUser = (state: RootState): State => state.user

export const getUserDetailsTransportResponseState = createSelector<
    RootState,
    State,
    TransportResponseState<BaseTransport<UserDetailsTransport>>
>(_getUser, user => user.userDetails)

const _getUserDetailsTransport = createSelector<
    RootState,
    TransportResponseState<BaseTransport<UserDetailsTransport>>,
    BaseTransport<UserDetailsTransport> | null
>(getUserDetailsTransportResponseState, userDetails => userDetails.transport)

export const getUserDetailsModel = createSelector<
    RootState,
    BaseTransport<UserDetailsTransport> | null,
    UserDetailsModel | null
>(_getUserDetailsTransport, transport =>
    transport ? getModel<UserDetailsTransport, UserDetailsModel>(transport.data, transport.meta) : null,
)
